import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class PreloaderService {
  private readonly _selector: string = 'rebac-splash-screen';

  constructor(@Inject(DOCUMENT) private readonly _document: Document) {}

  private _getElement() {
    return this._document.querySelector(this._selector);
  }

  hide(): void {
    const el = this._getElement();

    if (el) {
      el.addEventListener('transitionend', () => {
        el.className = 'rebac-splash-screen-hidden';
      });

      if (!el.classList.contains('rebac-splash-screen-hidden')) {
        el.className += ' rebac-splash-screen-fade-out';
      }
    }
  }
}
