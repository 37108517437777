import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, share } from 'rxjs';

export type MenuType = 'link' | 'sub' | 'extLink' | 'extTabLink' | 'sidenavSub';

export interface MenuPermissions {
  only?: string | string[];
  except?: string | string[];
}

export interface MenuTag {
  color: string;
  value: string;
}

export interface BaseMenuItem {
  name: string;
  route: string;
  permissions?: MenuPermissions;
  label?: MenuTag;
  badge?: MenuTag;
  type: MenuType;
}

export interface MenuItemWithIcon extends BaseMenuItem {
  icon: string;
}

export interface MenuItemLink extends MenuItemWithIcon {
  type: 'link' | 'extLink' | 'extTabLink';
  children?: never; // Keine Kinder erlaubt
}

export interface MenuItemSub extends MenuItemWithIcon {
  type: 'sub';
  children: Menu[]; // Menu wiederholend
}

export interface MenuItemSidenavSub extends MenuItemWithIcon {
  type: 'sidenavSub';
  children: MenuSidenavSubChild[];
}

export interface MenuSidenavSubChild extends BaseMenuItem {
  type: 'link';
  icon?: string;
  description?: string;
  label?: never;
  badge?: never;
  children?: never; // Keine Kinder erlaubt
}

export type Menu = MenuItemLink | MenuItemSub | MenuItemSidenavSub;

export interface Layer {
  item: Menu | MenuSidenavSubChild;
  parentNamePathList: string[];
  realRouteArr: string[];
}

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  private _menu$: BehaviorSubject<Menu[]> = new BehaviorSubject<Menu[]>([]);

  getAll(): Observable<Menu[]> {
    return this._menu$.asObservable();
  }

  change(): Observable<Menu[]> {
    return this._menu$.pipe(share());
  }

  set(menu: Menu[]): Observable<Menu[]> {
    this._menu$.next(menu);
    return this._menu$.asObservable();
  }

  add(menu: Menu) {
    const tmpMenu = this._menu$.value.slice();
    tmpMenu.push(menu);
    this._menu$.next(tmpMenu);
  }

  reset() {
    this._menu$.next([]);
  }

  buildRoute(routeArr: string[]): string {
    return routeArr.filter(item => item.trim()).join('/');
  }

  getItemName(routeArr: string[]): string {
    return this.getLevel(routeArr)[routeArr.length - 1];
  }

  private _isLeafItem(item: Menu | MenuSidenavSubChild): boolean {
    return !item.route || !item.children || item.children.length === 0;
  }

  private _isRouteEqual(routeArr: string[], realRouteArr: string[]): boolean {
    return (
      JSON.stringify(routeArr) === JSON.stringify(realRouteArr.filter(Boolean))
    );
  }

  getLevel(routeArr: string[]): string[] {
    let tmpArr: string[] = [];
    const menuItems = this._menu$.value;
    for (const menuItem of menuItems) {
      const unhandledLayer: Layer[] = [
        { item: menuItem, parentNamePathList: [], realRouteArr: [] },
      ];
      while (unhandledLayer.length > 0) {
        const nextUnhandledLayer: Layer[] = [];
        for (const ele of unhandledLayer) {
          const eachItem = ele.item;
          const currentNamePathList = [
            ...ele.parentNamePathList,
            eachItem.name,
          ];
          const currentRealRouteArr = [...ele.realRouteArr, eachItem.route];
          if (this._isRouteEqual(routeArr, currentRealRouteArr)) {
            tmpArr = currentNamePathList;
            break;
          }
          if (!this._isLeafItem(eachItem)) {
            /* Disable ESLint because the method `isLeafItem` checks the non-null assertion but typescript is not recognize that */
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const wrappedChildren = eachItem.children!.map(child => ({
              item: child,
              parentNamePathList: currentNamePathList,
              realRouteArr: currentRealRouteArr,
            }));
            nextUnhandledLayer.push(...wrappedChildren);
          }
        }
        unhandledLayer.length = 0;
        unhandledLayer.push(...nextUnhandledLayer);
      }
      if (tmpArr.length) {
        break;
      }
    }
    return tmpArr;
  }

  addNamespace(menu: Menu[] | MenuSidenavSubChild[], namespace: string) {
    for (const menuItem of menu) {
      menuItem.name = `${namespace}.${menuItem.name}`;
      if (menuItem.children && menuItem.children.length > 0) {
        this.addNamespace(menuItem.children, menuItem.name);
      }
    }
  }
}
