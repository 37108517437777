import { Inject, Injectable } from '@angular/core';
import { AppSettings, defaults, Scheme, Theme } from '../settings';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalStorageService } from '@shared/services';
import { MediaMatcher } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private readonly _key = 'rebac-frontend-layout-settings';
  private _options: AppSettings = { ...defaults };
  private _bodyElement!: HTMLBodyElement;
  private readonly _notify$ = new BehaviorSubject<Partial<AppSettings>>({});

  public schemeColor: Exclude<Scheme, 'auto'> = 'light';
  public themeColor: string = 'theme-default';

  get notify$(): Observable<Partial<AppSettings>> {
    return this._notify$.asObservable();
  }

  get options(): AppSettings {
    return this._options;
  }

  constructor(
    private readonly _store: LocalStorageService,
    private readonly _mediaMatcher: MediaMatcher,
    @Inject(DOCUMENT) private readonly _document: Document
  ) {
    const storedOptions = this._store.get<AppSettings>(this._key);
    this._options = { ...defaults, ...storedOptions };
    this.schemeColor = this.getSchemeColor();
    this._bodyElement = this._document.querySelector('body')!;
  }

  getSchemeColor(): Exclude<Scheme, 'auto'> {
    if (
      this._options.scheme === 'auto' &&
      this._mediaMatcher.matchMedia('(prefers-color-scheme)').media !==
        'not all'
    ) {
      const isSystemDark = this._mediaMatcher.matchMedia(
        '(prefers-color-scheme: dark)'
      ).matches;

      return isSystemDark ? 'dark' : 'light';
    } else {
      /* If the browser does not support `prefers-color-scheme`, set default to light */
      return this.options.scheme as Exclude<Scheme, 'auto'>;
    }
  }

  getThemeColor(): Theme {
    return this._options.theme;
  }

  setOptions(options: Partial<AppSettings>) {
    this._options = { ...this._options, ...options };
    this._store.set(this._key, this._options);
    this._notify$.next(this._options);
  }

  setLanguage(lang: string) {
    this.setOptions({ language: lang });
  }

  reset(): void {
    this._store.remove(this._key);
    this._options = { ...defaults };
    this._notify$.next(this._options);
  }

  updateScheme(): void {
    this.schemeColor = this.getSchemeColor();

    this._bodyElement.classList.remove('light', 'dark');

    this._bodyElement.classList.add(this.schemeColor);
  }

  updateTheme(): void {
    this.themeColor = this.getThemeColor();

    this._bodyElement.classList.forEach(className => {
      if (className.startsWith('theme-')) {
        this._bodyElement.classList.remove(className, className.split('-')[1]);
      }
    });

    this._bodyElement.classList.add(this.themeColor);
  }
}
